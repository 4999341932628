import "./scss/style.scss";
import {
  EuroOutlined,
  TeamOutlined,
  CloudDownloadOutlined,
  MailOutlined,
  TableOutlined,
} from "@ant-design/icons";
// import { wealthechBanner } from "/assets/wealthtech-banner.png";

function App() {
  return (
    <>
      <div class="container">
        <div class="centered-text-container">
          <img width="150" src="/assets/neobank.png" alt="" />
          <h1 class="text-center">neobank.services</h1>

          <span class="text-center-description">
            a suite of neobank services to automate your finances
          </span>

          <a class="join" href="https://tidycal.com/wealthtech/welcome">
            Book Demo
          </a>
        </div>
      </div>
      <div class="partners">
        <div class="partner">
          <span>
            <EuroOutlined style={{ fontSize: 50 }} />
          </span>
          <h3 style={{ textTransform: "uppercase", margin: 15 }}>
            Multi e-banking
          </h3>
          <p>
            Automate payment execution via multiple channels that are best
            suited for your business scenarios.
          </p>
        </div>
        <div class="partner">
          <span>
            <TeamOutlined style={{ fontSize: 50 }} />
          </span>
          <h3 style={{ textTransform: "uppercase", margin: 15 }}>
            LAYERS OF REVIEW
          </h3>
          <p>
            Set-up layers of review for crucial financial workflows so that
            everyone validates the actions.
          </p>
        </div>
        <div class="partner">
          <span>
            <MailOutlined style={{ fontSize: 50 }} />
          </span>
          <h3 style={{ textTransform: "uppercase", margin: 15 }}>
            EMAIL ACTIONS
          </h3>
          <p>
            Bring actions to the relevant actors right into their inbox so that
            they can stay up to date and trigger the actions timely.
          </p>
        </div>
        <div class="partner">
          <span>
            <CloudDownloadOutlined style={{ fontSize: 50 }} />
          </span>
          <h3 style={{ textTransform: "uppercase", margin: 15 }}>
            ALL IN THE CLOUD
          </h3>
          <p>
            Make all your documents available to your teams so that everyone is
            in-sync and they have access to the latest data.
          </p>
        </div>
        <div class="partner">
          <span>
            <TableOutlined style={{ fontSize: 50 }} />
          </span>
          <h3 style={{ textTransform: "uppercase", margin: 15 }}>DATA ENTRY</h3>
          <p>
            Our teams can help with the data scanning and entry so that your
            teams can focus on decision making.
          </p>
        </div>
      </div>
      <div className="footer-container">
        <div class="footer">
          <div className="info-container ">
            <span>Luxembourg</span>
            <span>200 Rue Principale</span>
            <span>L-5366 Munsbach</span>
          </div>
          <div className="info-container ">
            <span>Switzerland</span>
            <span>Baarerstrasse 25</span>
            <span>CH-6300 Zug</span>
          </div>
          <div className="info-container ">
            <span>France</span>
            <span>27 rue des Laitières</span>
            <span>94300 Vincennes</span>
          </div>
          {/* <div className="info-container ">
            <span>Support & General Information</span>
            <span>support@WealthTech.lu</span>
            <span>info@WealthTech.lu</span>
          </div> */}
          <div className="blank-data"></div>
          <div className="info-container">
            <a href="https://wealthtech.lu/" target="_blank">
              <img src="/assets/wealthtech-logo.png" alt=""></img>
            </a>
            <span className="trademark">
              Neobank.Services is a trademark of{" "}
              <a href="https://wealthtech.lu/" target="_blank">
                WealthTech
              </a>
              .
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
